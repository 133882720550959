<template>
  <div id="pay">
      <div class="alipay"></div>
      <!-- <div>{{ testData ?? '??????' }}</div>
      <div>{{ orderOptions }}</div>
      <div>{{ baseUrl }}</div> -->
  </div>
</template>
  
<script>
import axios from 'axios';
export default {
  name: 'Pay',
  data() {
    return {
      orderOptions: {},
      zfbcontent:'',
      baseUrl:'https://xhs-kdxgs-universal-api.kdxgs.com/api/pay/choose_pay_method',
      testData:''
    }
  },
  created(){
    this.orderOptions = this.$route.query
    if(this.orderOptions.pay_way==2){
      this.baseUrl = 'http://api-test-kdxgs.kdxgs.com/api/pay/choose_pay_method'
    }
    this.getPayOptions()
  },
  mounted() {
   
  },
  methods: {
    getPayOptions(){
      axios.post(
        this.baseUrl,
      {
        ...this.orderOptions
      },
      {
        headers: {

          "Api-Open-From": this.orderOptions.pay_type,
        }
      }
    ).then(res=>{
      this.testData = res
     if(this.orderOptions.pay_way==1){
      const {data} = res.data
      this.zfbcontent = res
      document.querySelector('.alipay').innerHTML = data
			document.forms[0].submit()
     }else {
      //微信
        window.location.href = res.data.data.h5_url+'&redirect_url=https://www.wechatpay.com'
     }
    })
    }
  }
}
</script>
  
<style></style>
  